class Carousel {

	constructor(selector, settings = {}) {
		
		const defaults = {
			interval: 3000,
			activeClass: 'is-selected',
		};
	  
		// Merge user settings with defaults
		this.settings = { ...defaults, ...settings };

		const parentElement = document.querySelector(selector);
		if (!parentElement) {
			throw new Error(`Element not found for selector: ${selector}`);
		}

		this.items = document.querySelector(selector).children;
		this.activeClass = this.settings.activeClass;
		this.currentIndex = 0;

		setInterval(() => {
			document.querySelector('body').classList.add('is-init');
		}, 500);

		if (this.items.length > 0) {
			this.items[this.currentIndex].classList.add(this.activeClass);
			this.start(this.settings.interval);
		}
	}

	start(interval) {
		this.timer = setInterval(() => {
			this.next();
		}, interval);
	}

	next() {
		this.items[this.currentIndex].classList.remove(this.activeClass);
		this.currentIndex = (this.currentIndex + 1) % this.items.length;
		this.items[this.currentIndex].classList.add(this.activeClass);
	}

	stop() {
		clearInterval(this.timer);
	}
}

export default Carousel