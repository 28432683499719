import Carousel from './carousel'

document.addEventListener("DOMContentLoaded", function (event) {

	document.querySelector("html").classList.remove("no-js")

	const carousel = new Carousel('.slider', {
		interval: 7500
	});

})